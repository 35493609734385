import { MonthPicker } from '@/src/components/foundations/Forms/MonthPicker';

import styles from './styles.module.scss';
import { PresenterProps } from './types';

import clsx from 'clsx';
import { isBefore, isAfter, startOfMonth, lastDayOfMonth } from 'date-fns';

type Props = PresenterProps;

export const MonthRangePickerPresenter: React.FC<Props> = (props) => {
  const MIN_SELECTABLE_START_DATE = new Date('2010/01/01');
  const MAX_SELECTABLE_END_DATE = lastDayOfMonth(new Date());

  return (
    <div className={styles.base}>
      <MonthPicker
        value={props.startDate}
        disabledDate={(date?: Date) => {
          return date
            ? isBefore(
                date,
                props.allowedDateRange?.startDate ?? MIN_SELECTABLE_START_DATE,
              ) ||
                isAfter(
                  date,
                  props.endDate ??
                    props.allowedDateRange?.endDate ??
                    MAX_SELECTABLE_END_DATE,
                )
            : false;
        }}
        onChange={(startDate) =>
          // endDate が未入力の場合は、 startDate の 同月の最終日を endDate にセットする
          props.onChange({
            startDate: startOfMonth(startDate),
            endDate: props.endDate ?? lastDayOfMonth(startDate),
          })
        }
        placement={props.placement ?? 'bottomStart'}
        isInForm={props.isInForm}
        oneTap={props.oneTap}
        showIcon={props.showIcon}
      />
      <span
        className={clsx(
          styles.tilde,
          props.isInForm ? styles.isInForm : styles.isDefaultForm,
        )}
      >
        ~
      </span>
      <MonthPicker
        value={props.endDate}
        disabledDate={(date?: Date) => {
          return date
            ? isBefore(
                date,
                props.startDate ??
                  props.allowedDateRange?.startDate ??
                  MIN_SELECTABLE_START_DATE,
              ) ||
                isAfter(
                  date,
                  props.allowedDateRange?.endDate ?? MAX_SELECTABLE_END_DATE,
                )
            : false;
        }}
        onChange={(endDate) =>
          // startDate が未入力の場合は、 endDate の 同月の最初日を startDate にセットする
          props.onChange({
            startDate: props.startDate ?? startOfMonth(endDate),
            endDate: lastDayOfMonth(endDate),
          })
        }
        placement={props.placement ?? 'bottomEnd'}
        isInForm={props.isInForm}
        oneTap={props.oneTap}
        showIcon={props.showIcon}
      />
    </div>
  );
};
