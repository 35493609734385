import { OwnDepartmentFilterModal } from '@/src/components/features/UtilModal/OwnDepartmentFilterModal';
import { Chip } from '@/src/components/foundations/Buttons/Chip';
import { useModal } from '@/src/hooks/useModal';

type Props = {
  onSubmit: React.ComponentProps<typeof OwnDepartmentFilterModal>['onSubmit'];
  selectedOwnDepartmentIds: string[];
  size?: 'small' | 'medium';
};

export const OwnDepartmentFilterChipPresenter: React.FC<Props> = (props) => {
  const departmentsFilterModal = useModal();

  return (
    <>
      <Chip
        size={props.size ?? 'small'}
        status={props.selectedOwnDepartmentIds.length > 0 ? 'active' : 'normal'}
        onClick={departmentsFilterModal.show}
      >
        {props.selectedOwnDepartmentIds.length > 0
          ? `診療科：${props.selectedOwnDepartmentIds.length}`
          : '診療科'}
      </Chip>
      {departmentsFilterModal.isShown && (
        <OwnDepartmentFilterModal
          isShown={departmentsFilterModal.isShown}
          onClose={departmentsFilterModal.hide}
          selectedOwnDepartmentIds={props.selectedOwnDepartmentIds}
          onSubmit={(values) => {
            departmentsFilterModal.hide();
            props.onSubmit(values);
          }}
        />
      )}
    </>
  );
};
